<template>
  <div class="pt-5">
    <div v-for="brand in brands" :key="brand.id" class="border-bottom border-dark">
      <b-button block variant="light" class="py-3 d-flex justify-content-between align-items-center"
        :class="brand.visible ? null : 'collapsed'"
        :aria-expanded="brand.visible ? true : false"
        @click="clickBrandHeader(brand)"
      >
        <span>{{ brand.name }}</span>
        <b-icon-chevron-up :rotate="brand.visible? 0: 180"></b-icon-chevron-up>
      </b-button>
      <b-collapse v-model="brand.visible">
        <div class="p-4">
          <p class="line-height-1_8" v-html="nl2br(brand.description)">
          </p>
          <template v-if="brand.products">
            <div v-for="product in brand.products" :key="brand.id + '-' + product.id" class="border-bottom border-dark">
              <b-button block variant="light" class="py-3 d-flex justify-content-between align-items-center"
                :class="product.visible ? null : 'collapsed'"
                :aria-expanded="product.visible ? true : false"
                @click="clickProductHeader(product)"
              >
                <span>{{ product.title.rendered }}</span>
                <b-icon-circle v-if="!product.visible"></b-icon-circle>
                <b-icon-circle-fill v-else></b-icon-circle-fill>
              </b-button>
              <b-collapse v-model="product.visible">
                <img v-if="product._embedded['wp:featuredmedia'] && product._embedded['wp:featuredmedia'][0]" class="img-fluid" :src="product._embedded['wp:featuredmedia'][0].source_url">
                <div class="py-4">
                  <p class="line-height-1_8" v-html="nl2br(product.content.rendered)">
                  </p>
                </div>
              </b-collapse>
            </div>
          </template>
          <div v-else class="text-center">
            <b-spinner v-if="loading" label="Spinning"></b-spinner>
          </div>
        </div>
      </b-collapse>
    </div>
    <b-spinner v-if="loading2" label="Spinning"></b-spinner>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'BrandList',
  components: {
  },
  data() {
    return {
      // brands: []
      loading: false,
      loading2: false
    }
  },
  mounted() {
  },
  computed: {
    ...mapState({
      brands: state => state.brands,
      types: state => state.types,
    })
  },
  methods: {
    ...mapActions({
      syncBrands: 'syncBrands',
      syncTypes: 'syncTypes',
      syncBrandProducts: 'syncBrandProducts'
    }),
    clickBrandHeader(brand) {
      brand.visible = !brand.visible
      if (!brand.products) {
        this.syncBrandProducts({brandId: brand.id, axios: this.axios})
        this.loading = true
        setTimeout(() => {
          this.finishLoading(brand)
        }, 1000);
      } else {
        this.loading = false
      }
    },
    clickProductHeader(product) {
      console.log('clickProductHeader', product)
      product.visible = !product.visible
      this.loading2 = true
      setTimeout(() => {
        this.loading2 = false        
      }, 500);
    },
    finishLoading(brand) {
      if(brand.products) {
        this.loading = false
      } else {
        this.finishLoading(brand)
      }
    },
    nl2br(str) {
        str = str.replace(/\r\n/g, "<br />");
        str = str.replace(/(\n|\r)/g, "<br />");
        return str;
    }
  }
}
</script>
